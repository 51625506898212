import React, { useRef } from "react";
import generatePDF from "react-to-pdf";

import { useState } from "react";
import { NavLink } from "react-router-dom";
import ReservationDetailsCard from "./ReservationDetailsCard";
import ParkingLotSummary from "./ParkingLotSummary";
import PaymentSummary from "./PaymentSummary";
import Directions from "./Directions";
import ParkingLotDetails from "./ParkingLotDetails";
import ParkingPolicies from "./ParkingPolicies";
import TopPageDetail from "./TopPageDetail";
import { Reservation } from "../types/types";

type ReservationDetailsProps = {
  details: Reservation
}
const ReservationDetails = ({ details }: ReservationDetailsProps) => {
  const targetRef = useRef();
  const [forPrint, setForPrint] = useState(false);
  const handlePrint = async () => {
    setForPrint(true);
    setTimeout(() => {
      generatePDF(targetRef, { filename: "parking_maniac_reservation.pdf" });
      setForPrint(false);
    }, 500);


  };

  const [activeContent, setActiveContent] = useState("summary");


  const handleTabClick = (tabName) => {
    setActiveContent(tabName);

    // Scroll to the corresponding section
    const section = document.getElementById(tabName);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>

      <div ref={targetRef}>
        <TopPageDetail
          forPrint={forPrint}
          details={details}
          doPrint={() => {
            handlePrint();
          }}
        />

        <div className="bg-[#FAFAFA] md:py-20 pb-10 pt-0">
          <div className="container mx-auto">
            <div className={`md:flex md:flex-row flex-col gap-10 ${!forPrint ? 'hidden' : ''} `}>
              <div className="md:max-w-[65%] max-w-[100%] w-full">
                <div className="block">
                  <h3 className="clash text-[24px] text-[#2B2B2B]">
                    Reservation Details
                  </h3>
                  {/* <p className='text-[#707070] text-[12px]'>You can <NavLink className='underline text-[#2B2B2B]' to='#'>cancel</NavLink> and <NavLink className='underline text-[#2B2B2B]' to='#'>edit</NavLink> your reservations until check-in time!</p> */}

                  <div className="flex flex-col gap-6">
                    <ReservationDetailsCard details={details} />

                    <ParkingLotSummary details={details} />

                    {/* <ParkingLotDetails /> */}

                    {/* <ParkingPolicies /> */}
                  </div>
                </div>
              </div>

              <div className="md:max-w-[35%] max-w-[100%] w-full flex flex-col gap-6">
                <PaymentSummary doPrint={() => {
                  handlePrint();
                }} forPrint={forPrint} details={details} />
                <Directions  details={details}/>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className="bg-[#FAFAFA] md:py-20 pb-10 pt-0">
        <div className="container mx-auto">
          <div className="md:hidden flex flex-col gap-5">
            <div className="md:hidden flex gap-1 items-center bg-white  sticky top-[0px] z-[999]">
              <NavLink
                to="#reservation"
                className={` text-[#707070] text-[12px] font-[400] py-4 px-4 border-b-[2px] border-transparent hover:border-[#2EABE3]
                         ${activeContent === "summary" ? "border-[#2EABE3]" : ""
                  }
                         `}
                onClick={() => handleTabClick("reservation")}
              >
                Reservation
              </NavLink>
              <NavLink
                to="#direction"
                className={`text-[#707070] text-[12px] font-[400] py-4 px-4 border-b-[2px] border-transparent hover:border-[#2EABE3]
                    ${activeContent === "direction" ? "border-[#2EABE3]" : ""}
                `}
                onClick={() => handleTabClick("direction")}
              >
                Direction
              </NavLink>
              {/* <NavLink
                  to="#policies"
                  className={`text-[#707070] text-[12px] font-[400] py-4 px-4 border-b-[2px] border-transparent hover:border-[#2EABE3] 
                    ${activeContent === "policies" ? "border-[#2EABE3]" : ""}
                `}
                  onClick={() => handleTabClick("policies")}
                >
                  Policies
                </NavLink> */}
            </div>

            <div id="reservation">
            <ReservationDetailsCard details={details} />
              <PaymentSummary doPrint={() => {
                  handlePrint();
                }} forPrint={forPrint} details={details}  />
              <ParkingLotSummary  details={details}  />
            </div>
            <div className="tab-divider"></div>
            <div id="direction">
              <Directions details={details}/>
            </div>
            <div id="policies">
              {/* <ParkingLotDetails /> */}
              {/* <ParkingPolicies /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReservationDetails;
