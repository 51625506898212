import React from 'react'
import ReactStars from "react-rating-stars-component";

const RatingStarsBar = ({ rating, showNumbers }) => {

  const firstExample = {
    size: 30,
    value: rating ? rating : 0,
    edit: false
  };

  return (
    <div>
      <div className="flex items-center gap-1">
        <ReactStars {...firstExample} />
        {showNumbers &&
          <p className='text-[#707070] text-[10px] font-[400] m-0'><span className='text-[#000000] text-[12px] font-[400]'>{Number(rating).toFixed(1)}</span></p>
        }
      </div>
    </div>
  )
}

export default RatingStarsBar

