import React from 'react'

const EnterInfo = () => {
    return (
        <div className="w-full bg-[#EEEEEE] lg:p-[15px] p-[10px] rounded-[4px]">
            <h5 className='text-[#707070] text-[12px] font-[400] uppercase'>Summary</h5>
            <h3 className='mt-3 text-[#2B2B2B] text-[20px] font-[400]'>Bolt Parking</h3>
            <div className="flex items-center justify-between">
                <h5 className='text-[#707070] text-[12px] font-[500]'>2 hours</h5>
                <h5 className='text-[#707070] text-[12px] font-[500]'>$34.83</h5>
            </div>
            <div className="flex items-center justify-between mt-6 border-t border-[#C4C4C4] pt-3">
                <h5 className='text-[#2B2B2B] text-[16px] font-[400]'>Total </h5>
                <h5 className='text-[#2B2B2B] clash text-[16px] font-[500]'>$61.92</h5>
            </div>
            <div className="mt-4 bg-white p-[15px] rounded-[4px]">
                <p className='text-[#707070] text-[12px] font-[400] uppercase'>parking reservation Details</p>
                <div className="flex lg:flex-col flex-row lg:gap-5 gap-3 mt-3">
                    <div className="w-full bg-[#E6F2F8] p-[7px] rounded-[4px]">
                        <div className="flex justify-between">
                            <p className='text-[#2EABE3] text-[10px] font-[700] uppercase'>Check in</p>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 1V3" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 1V3" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.5 5H10.5" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='text-[#2EABE3] text-[10px] font-[400] mt-2'>02/09/2024 - 1:58am</p>
                    </div>
                    <div className="w-full bg-[#E6F2F8] p-[7px] rounded-[4px]">
                        <div className="flex justify-between">
                            <p className='text-[#2EABE3] text-[10px] font-[700] uppercase'>Check in</p>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 2H2.5C1.94772 2 1.5 2.44772 1.5 3V10C1.5 10.5523 1.94772 11 2.5 11H9.5C10.0523 11 10.5 10.5523 10.5 10V3C10.5 2.44772 10.0523 2 9.5 2Z" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8 1V3" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4 1V3" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.5 5H10.5" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='text-[#2EABE3] text-[10px] font-[400] mt-2'>02/09/2024 - 1:58am</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterInfo
