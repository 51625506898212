import React from 'react'
import SatisfiedUser from '../assets/images/satisfied-user.svg'
import { NavLink } from 'react-router-dom'

const SatisfiedUsersSection = () => {
  return (
    <>
    <section className='satisfied-section'>
        <div className="container mx-auto">
            <div className="lg:flex md:grid grid grid-cols-1 lg:gap-0 md:gap-2 gap-3 flex-wrap">
                <div className="lg:w-[30%] md:w-[100%] text-center relative">
                    <img className=' max-w-[200px] md:max-w-[60%] lg:max-w-[100%] mx-auto d-block ' src={SatisfiedUser} alt='' />
                </div>
                <div className="lg:w-[70%] md:w-[100%] lg:py-20 py-10 lg:pl-16">
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 lg:gap-0 md:gap-0">
                            <div className="satisfy-top mx-auto lg:mx-0 md:mx-0">
                                <h4>+3 MILLION</h4>
                                <p>SATISFIED USERS</p>
                            </div>
                            <div className="satisfy-top mx-auto lg:mx-0 md:mx-0 lg:pl-5 md:pl-5">
                                <h4>30</h4>
                                <p>STATES</p>
                            </div>
                            <div className="satisfy-top mx-auto lg:mx-0 md:mx-0">
                                <h4>65+</h4>
                                <p>AIRPORTS</p>
                            </div>
                            <div className="satisfy-top mx-auto lg:mx-0 md:mx-0">
                                <h4>1800+</h4>
                                <p>Parking Lots</p>
                            </div>
                    </div>
                    <div className="grid sm:grid-cols-4 grid-cols-2 md:mt-16 mt-12 sm:gap-2 gap-4 sm:text-left text-center">
                            <div className="satisfy-bottom">
                                <NavLink to='#'> LaGuardia (LGA)</NavLink>
                                <NavLink to="#">Denver (DEN) </NavLink>
                                <NavLink to="#">O’Hare (ORD) </NavLink>
                            </div>
                            <div className="satisfy-bottom">
                                <NavLink to="#">Los Angeles (LAX)</NavLink>
                                <NavLink to="#">Harry Reid (LAS) </NavLink>
                                <NavLink to="#">Orlando (MCO) </NavLink>
                            </div>
                            <div className="satisfy-bottom">
                                <NavLink to="#">Logan (BOS)</NavLink>
                                <NavLink to="#">Louis Armstrong (MSY) </NavLink>
                                <NavLink to="#">John Wayne (SNA) </NavLink>
                            </div>
                            <div className="satisfy-bottom">
                                <NavLink to="#">Kansas (MCI)</NavLink>
                                <NavLink to="#">San Antonio (SAT) </NavLink>
                                <NavLink to="#">John F. Kennedy (JFK) </NavLink>
                            </div>
                            
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default SatisfiedUsersSection
