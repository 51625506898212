import React, { useEffect } from 'react'
import SearchBox from '../home-components/SearchBox'
import ParkAreaCard from '../search-page-component/ParkAreaCard'
import { useState } from 'react'
import ParkingTabContent from '../home-components/ParkingTabContent'
import { useCallback } from 'react'
import { Fade } from 'react-reveal';
import PaymentModal from '../components/PaymentModal'
import { fetchPartners } from '../service/UserApi'
import dayjs, { Dayjs } from 'dayjs'
import { Airport, PartnerInfo } from '../types/types'
import NotSelected from '../assets/images/noparkingselectyet.svg'
import reservationActions from '../actions/ReservationActions'
import { GlobalContext } from '../globalContext'
import moment from 'moment'
import useBackButton from '../../utils/useBackButton'
import { unstable_HistoryRouter } from 'react-router-dom'
import { GoogleMap, useJsApiLoader, MarkerF as Marker } from '@react-google-maps/api';
import AirportIcon from '../assets/images/airport-icon.png'
import ParkingIcon from '../assets/images/parking-icon.png'
import { useNavigate } from "react-router-dom";
import Spinner from '../components/Spinner'
import AdminLogo from "../assets/images/logo.svg";
import { checkifPartnerHasValidTime } from '../../utils/utils'

const SearchAirport = () => {

    const { dispatch } = React.useContext(GlobalContext);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,

        });
    };

    const navigate = useNavigate();

    const [showParkingInfo, setShowParkingInfo] = useState('')
    const [fullScreen, setFullScreen] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [partners, setPartners] = useState<PartnerInfo[]>([])
    const [selectedPartner, setSelectedPartner] = useState<PartnerInfo | undefined>(undefined)
    const [airportId, setAirportId] = useState<string | null>(null)
    const [selectedAirport, setSelectedAirport] = useState<Airport | null>(null)
    const [checkinData, setCheckInDate] = useState<Dayjs | null>(null)
    const [checkoutDate, setCheckoutDate] = useState<Dayjs | null>(null)
    const [showSort, setShowSort] = useState(false)
    const [sortType, setSortType] = useState('')
    const [loadingPartners, setLoadingPartners] = useState(false)
    const [filteredPartners, setFilteredPartners] = useState(partners);
    const [originalPartner, setoriginalPartner] = useState([]);
    const [datechangeFlag, setDateChangeFlag] = useState(0);
    const [markers, setMarkers] = useState<any[]>([]);

    const urlParams = new URLSearchParams(window.location.search);
    const checkIn = urlParams.get('checkIn');
    const checkOut = urlParams.get('checkOut');
    const airport_id = urlParams.get('airport_id');
    const [center, setCenter] = useState<{ lat: number, lng: number } | undefined>();
    useEffect(() => {
        if (checkIn && checkOut) {
            const checkInDateTime = dayjs(checkIn, 'YYYY-MM-DD hh:mm A');
            const checkOutDateTime = dayjs(checkOut, 'YYYY-MM-DD hh:mm A');
            setCheckInDate(checkInDateTime);
            setCheckoutDate(checkOutDateTime);
        }
        if (airport_id) {
            setAirportId(airport_id)
        }
    }, [checkIn, checkOut, airport_id]);
    const onClosePaymentModal = useCallback(() => {
        setPaymentModal(false);
    }, [paymentModal]);

    const goFull = useCallback((e) => {
        setFullScreen(e)
    }, []);

    const buildUrlParams = () => {
        const params = new URLSearchParams();
        if (airportId) params.set('airport_id', airportId);
        if (checkinData) params.set('checkIn', checkinData.format('YYYY-MM-DD hh:mm A'));
        if (checkoutDate) params.set('checkOut', checkoutDate.format('YYYY-MM-DD hh:mm A'));
        return params.toString();
    };
    const ShowParkingInfo = (selectedParking) => {
        setShowParkingInfo(selectedParking)
        if (window.innerWidth < 1024) {
            setFullScreen(true);
        }
    }
    useEffect(() => {
        if (airportId != null) {
            getPartners(airportId)
        }

    }, [airportId, checkIn, checkOut])


    useEffect(() => {
        scrollToTop()
    }, [])


    const getPartners = async (airportId: string) => {
        try {
            setLoadingPartners(true)
            const response: PartnerInfo[] = await fetchPartners(airportId);

            // FOR VALID TIME BLOCK
            const allValidPartners = response.filter(it => it.active == '1')
            const notValidPartners = response.filter(it => it.active != '1')
            const tempValidPartners = allValidPartners.map(item => {
                const checkTime = checkifPartnerHasValidTime(dayjs(checkIn, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss'), dayjs(checkOut, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss'), item)
                return { ...item, isValidTime: (checkTime == '' ? 1 : 0) }
            })
            const unRankedPartners = tempValidPartners.filter(it=> it.rank=='')
            const rankedPartners = tempValidPartners.filter(it=> it.rank!='')
             // Sort partners by rank
            rankedPartners.sort((a, b) => a.rank - b.rank);
            

         const tempPartners = [...rankedPartners,...unRankedPartners]
            tempPartners.sort((a, b) => b.isValidTime - a.isValidTime);
            // END FOR VALID TIME BLOCK
            const combinePartners = [...tempPartners,...notValidPartners]
            setPartners(combinePartners)// replace `tempPartners` with `response`
            if (response) {
                let tempMarkers = []
                for (let index = 0; index < response.length; index++) {
                    const partner = response[index];
                    if (partner.latlng) {
                        const [lat, lng] = partner?.latlng.split(',')
                        if (lat && lng) {
                            tempMarkers.push({ lat: Number(lat), lng: Number(lng), partner })
                        }
                    }

                }
                setMarkers(tempMarkers)
            }
            setFilteredPartners(combinePartners)// replace `tempPartners` with `response`
        } catch (error) {

            setPartners([])
            setFilteredPartners([])
        } finally {
            setLoadingPartners(false)
        }
    }



    const sortByCheapest = () => {
        console.log('Before sorting by cheapest:', filteredPartners);
        const sortedData = [...filteredPartners].sort((a, b) => {
            const priceA = a.actual_price !== null ? parseFloat(a.actual_price) : 0;
            const priceB = b.actual_price !== null ? parseFloat(b.actual_price) : 0;
            return priceA - priceB;
        });
        console.log('After sorting by cheapest:', sortedData);
        setFilteredPartners(sortedData);
    };
    const sortByDistance = () => {
        console.log('Before sorting by cheapest:', filteredPartners);
        const sortedData = [...filteredPartners].sort((a, b) => {
            const priceA = a.mile !== null ? parseFloat(a.mile) : 0;
            const priceB = b.mile !== null ? parseFloat(b.mile) : 0;
            return priceA - priceB;
        });
        console.log('After sorting by cheapest:', sortedData);
        setFilteredPartners(sortedData);
    };

    const ClearData = () => {

        setFilteredPartners(partners);
    };

    const sortByHighest = () => {
        console.log('Before sorting by highest:', filteredPartners);
        const sortedData = [...filteredPartners].sort((a, b) => {
            const priceA = a.starting_price !== null ? parseFloat(a.starting_price) : 0;
            const priceB = b.starting_price !== null ? parseFloat(b.starting_price) : 0;
            return priceB - priceA;
        });
        console.log('After sorting by highest:', sortedData);
        setFilteredPartners(sortedData);
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAAfEDhqDqqEO9unfhxcFQRdA2FVsm-97s'
    });
    return (
        <>
            {/* {paymentModal &&
                <PaymentModal onClosePaymentModal={onClosePaymentModal} />
            } */}




            <div className="fader">



                <SearchBox onAirportSelected={(airport) => {
                    setSelectedPartner(undefined)
                    setSelectedAirport(airport)
                    setAirportId(airport.id + '')
                    if (airport.latlng) {
                        const [lat, lng] = airport?.latlng.split(',')
                        if (lat && lng) {
                            setCenter({ lat: Number(lat), lng: Number(lng) })
                        }
                    }
                    const urlParamsCurrent = new URLSearchParams(window.location.search);

                    const selectedData: any = {
                        checkIn,
                        checkOut,
                    };
                    // const airportId = urlParamsCurrent.get('airport_id')
                    if (airport?.id) { selectedData['airport_id'] = airport?.id }

                    const urlParams = new URLSearchParams(selectedData).toString();


                    window.history.pushState(null, '', `?${urlParams}`);

                }}
                    onFilters={(selectedFilters) => {
                        if (selectedFilters.length < 1) {
                            setFilteredPartners(partners); // Reset to original list
                        } else {
                            const filterMap = {
                                "Covered Self Park": "parkingTypes_coveredSelfPark",
                                "Uncovered Self Park": "parkingTypes_unCoveredSelfPark",
                                "Covered Valet": "parkingTypes_coveredVallet",
                                "Uncovered Valet": "parkingTypes_unCoveredVallet",
                            };

                            const newFilteredPartners = partners.filter((partner) => {
                                if (!partner.options) {
                                    // If options is null, consider it as not matching any filters
                                    return false;
                                }
                                const options = JSON.parse(partner.options);
                                return selectedFilters.every((filter) => options[filterMap[filter]]);
                            });

                            setFilteredPartners(newFilteredPartners);
                        }
                    }}

                    onDateChanged={() => {
                        // setDateChangeFlag(moment().milliseconds())
                    }}
                    page="inner" />
                {(checkIn == null || checkOut == null) && <p className='py-[50px] px-2 w-full text-center text-[#2EABE3] text-[20px] font-[700]'>Please select Valid check in and check out date</p>}
                {(checkIn != null || checkOut != null) && <div className="flex bg-[#EEEEEE]">
                    <div className={` ${fullScreen ? 'w-[0] max-w-[0] min-w-[0] hidden  overflow-hidden ' : 'lg:w-[35%] lg:min-w-[35%] w-[100%] min-w-[100%] md:px-5 px-1 border-t border-[#D1D1D1] py-2'}   `}>
                        <div className="flex  items-center justify-between gap-2 flex-wrap pb-2 ">
                            {!loadingPartners && <p className='text-[#707070] text-[13px] text-[400]'>{partners.length} Parking spot{partners.length > 1 ? 's' : ''} near ‘{selectedAirport?.name}’</p>}
                            {loadingPartners && <p className='text-[#707070] text-[13px] text-[400]'></p>}
                            <div className="relative ">
                                <div className="flex gap-2 items-center">
                                    <button className='text-[13px] font-[700] text-[#2B2B2B] py-2 px-5 border border-[#2EABE3] rounded-[4px]'
                                        onClick={() => {
                                            setShowSort(true)
                                        }}
                                        type='button'>Sort by {sortType != '' ? (": " + sortType) : ''} </button>
                                    {sortType != '' && <button onClick={() => {
                                        setSortType('')
                                        ClearData()
                                        setShowSort(false)
                                    }} type='button' className='clearSort'>Clear</button>}
                                </div>
                                {showSort &&
                                    <>

                                        <div className="fixed top-0 left-0 w-full h-screen " onClick={() => {
                                            setShowSort(false)
                                        }}></div>
                                        <div className="filters-drop min-w-[140px]   duration-150 group-hover:opacity-100 rounded-[10px]  flex flex-col gap-1 absolute right-0 bg-[#ffffff]  px-2 py-2 shadow-lg" style={{ zIndex: '999999999' }}>

                                            {/* <p className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150"> 
                                                Low Price to High</p> */}
                                            <p onClick={() => {
                                                sortByDistance()
                                                setSortType('Closest')
                                                setShowSort(false)
                                            }} className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">
                                                Distance</p>
                                            <p onClick={() => {
                                                sortByCheapest()
                                                setSortType('Cheapest')
                                                setShowSort(false)
                                            }} className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">
                                                Price</p>
                                            {/* <p onClick={() => {
                                                sortByHighest()
                                                setSortType('Highest')
                                                setShowSort(false)
                                            }} className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">
                                                Highest</p> */}


                                        </div>
                                    </>
                                }



                            </div>
                        </div>
                        {!loadingPartners && <div className="grid flex-col gap-2">

                            {filteredPartners.length > 0 ? filteredPartners.map((item) => (
                                <Fade>

                                    <ParkAreaCard
                                        errorMessage={item?.isValidTime == 0 ? "Time mismatch" : ''} // remove this for timing condition
                                        OpenPaymentModal={(open, partner) => {
                                            setPaymentModal(true)
                                            setSelectedPartner(partner)
                                            dispatch(reservationActions.updatePartner(partner))
                                            dispatch(reservationActions.updateTaxes(partner.taxes))
                                            if (partner?.starting_price) {
                                                dispatch(reservationActions.updateDailyRate(partner?.starting_price))
                                            }
                                            if (partner?.deposit) {
                                                dispatch(reservationActions.updateDeposit(partner?.deposit))
                                            }
                                            navigate('/payment')
                                        }}
                                        // HotDeal={true}
                                        partner={item}
                                        onPartnerSelectd={(partner) => {
                                            console.log({ partner });

                                            setSelectedPartner(partner)
                                            dispatch(reservationActions.updatePartner(partner))
                                            dispatch(reservationActions.updateTaxes(partner.taxes))
                                            if (partner?.starting_price) {
                                                dispatch(reservationActions.updateDailyRate(partner?.starting_price))
                                            }
                                            if (partner?.deposit) {
                                                dispatch(reservationActions.updateDeposit(partner?.deposit))
                                            }
                                            const urlParams = buildUrlParams();
                                            navigate(`/partner_details/${item.id}?${urlParams}`);
                                        }}
                                        selectParkingArea={ShowParkingInfo}
                                    />
                                </Fade>
                            )) : <p className='text-center text-[20px] py-[40px] min-h-[40vh] flex items-center justify-center'>No Parking lots Near</p>
                            }


                        </div>}
                        {loadingPartners && <div className="grid flex-col gap-2">
                            <div className="flex items-center justify-between max-w-[60%] mx-auto complete-loading"> <img src={AdminLogo} alt="" /> </div>
                        </div>}
                    </div>

                    <div className={` ${fullScreen ? 'w-[100%] ' : 'lg:w-[65%] w-[0] lg:block hidden '}   map-site`}>
                        {
                            selectedPartner != undefined ?
                                <ParkingTabContent
                                    OpenPaymentModal={(open, partner) => {
                                        setPaymentModal(true);
                                        setSelectedPartner(partner);
                                        dispatch(reservationActions.updatePartner(partner));
                                        dispatch(reservationActions.updateTaxes(partner.taxes));
                                        if (partner?.starting_price) {
                                            dispatch(reservationActions.updateDailyRate(partner?.starting_price));
                                        }
                                        if (partner?.deposit) {
                                            dispatch(reservationActions.updateDeposit(partner?.deposit));
                                        }

                                        navigate('/payment')
                                    }}
                                    callGoFull={goFull}
                                    onShowMap={() => {
                                        setSelectedPartner(undefined)
                                    }}
                                    partner={selectedPartner}
                                    fullScreen={false}
                                    AreaName={''}
                                /> :
                                isLoaded ? (
                                    <div className=" sticky top-0">
                                        <GoogleMap
                                            mapContainerStyle={{ width: '100%', height: '100vh', position: 'sticky', top: '0' }}
                                            center={center ? center : { lat: 37.7749, lng: -122.4194 }} // Set your desired initial center coordinates
                                            zoom={14} // Set your desired initial zoom level
                                        >
                                            {/* Add markers or other components here */}
                                            {center && <Marker
                                                position={center}
                                                icon={AirportIcon}
                                            />}
                                            {markers.map(marker => (
                                                <Marker
                                                    key={marker.id}
                                                    position={{ lat: marker.lat, lng: marker.lng }}
                                                    icon={ParkingIcon}
                                                    onClick={() => {
                                                        const partner = marker?.partner
                                                        setSelectedPartner(partner);
                                                        dispatch(reservationActions.updatePartner(partner))
                                                        dispatch(reservationActions.updateTaxes(partner.taxes))
                                                        if (partner?.starting_price) {
                                                            dispatch(reservationActions.updateDailyRate(partner?.starting_price))
                                                        }
                                                        if (partner?.deposit) {
                                                            dispatch(reservationActions.updateDeposit(partner?.deposit))
                                                        }
                                                        const urlParams = buildUrlParams();
                                                        navigate(`/partner_details/${partner.id}?${urlParams}`);
                                                    }}
                                                // icon={otherMarkerIcon}
                                                />
                                            ))}
                                        </GoogleMap>
                                    </div>
                                ) : <div className='flex align-items-center justify-center' ><Spinner/></div>
                        }
                    </div >
                </div >}
            </div >
        </>


    )
}

export default SearchAirport
