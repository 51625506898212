import React from 'react'
import InputField from '../components/InputField'
import { NavLink } from 'react-router-dom'
import AppleLogo from '../assets/images/apple-logo.svg'
import GooglePlay from '../assets/images/google-play-icon.svg'

const EnterDetailForm = () => {
    return (
        <>

            <div className="flex items-center gap-2">
                <h3 className='text-[#2B2B2B] text-[400] lg:text-[24px] text-[18px] clash'>Enter Details to Signup</h3>
                <span className='block w-fit text-[#2EABE3] font-[400] lg:text-[12px] text-[10px] bg-[#E6F2F8] lg:py-[6px] py-[4px] lg:px-[10px] px-[7px] rounded-full'>Sign up and Save 10%</span>
            </div>
            <p className='text-[#707070] text-[12px] font-[400]'>*Mandatory field</p>
            <div className="grid sm:grid-cols-2 grid-cols-1 md:gap-8 gap-4 mt-4">
                <InputField type='text' label='First Name' />
                <InputField type='text' label='Last Name' />
                <InputField type='email' label='Email Address' />
                <InputField type='text' label='Phone Number' />
            </div>
            <div className="flex items-center gap-2 mt-4">
                <input className='rounded-[4px] bg-[#D9D9D9] border-none checkbox' type='checkbox' />
                <p className='text-[#707070] text-[12px] font-[400]'>by clicking here, i agree with <NavLink to="#" className='text-[#2B2B2B] underline'>Privacy Policies</NavLink> and <NavLink to="#" className='text-[#2B2B2B] underline'>Terms and Conditions</NavLink></p>
            </div>
            <div className="mt-6">
                <button type='button' className='mt-3 block bg-[#2EABE3] rounded-[4px] text-[16px] font-[500] text-[#FAFAFA]  py-4 w-full'>Signup  & Checkout</button>

            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-8 gap-2 mt-3">
                <button type='button' className='mt-3 flex items-center justify-center gap-2 border border-[#C4C4C4] rounded-[10px] text-[16px] font-[500] text-[#2B2B2B] lg:py-4 md:py-4 py-4 w-full'>
                    <img src={GooglePlay} />
                    Continue with Google
                </button>

                <button type='button' className='mt-3 bg-[#2B2B2B] flex items-center justify-center gap-2 border border-[#2B2B2B] rounded-[10px] text-[16px] font-[500] text-[#FFFFFF] lg:py-4 md:py-4 py-4 w-full'>
                    <img src={AppleLogo} />
                    Continue with Apple ID
                </button>
            </div >
            <div className="flex md:items-start justify-between mt-8 md:flex-row flex-col items-center md:gap-0 gap-4">
                <NavLink to='#' className='flex items-center gap-2 text-[#707070] text-[12px] font-400'>
                    Continue as Guest <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 5.49993C0 5.78182 0.209476 6.01479 0.481258 6.05166L0.556814 6.05674L11.6931 6.05674C12.0006 6.05674 12.2499 5.80745 12.2499 5.49993C12.2499 5.21804 12.0404 4.98507 11.7687 4.9482L11.6931 4.94312L0.556814 4.94312C0.249294 4.94312 0 5.19241 0 5.49993Z" fill="#707070" />
                        <path d="M6.80847 9.5781C6.59055 9.79509 6.58979 10.1476 6.80677 10.3656C7.00403 10.5637 7.31333 10.5823 7.53166 10.421L7.59422 10.3673L12.0859 5.89492C12.2846 5.69708 12.3026 5.38666 12.1401 5.16834L12.0859 5.10581L7.59426 0.632732C7.37636 0.415733 7.0238 0.416463 6.8068 0.634362C6.60953 0.832452 6.5922 1.14183 6.75439 1.35948L6.80843 1.42181L10.9036 5.50057L6.80847 9.5781Z" fill="#707070" />
                    </svg>
                </NavLink>

                <div className="  text-right">
                    <h3 className='text-[#707070] text-[24px] font-[400]'>Already have an account?</h3>
                    <NavLink className='text-[#2B2B2B] items-center gap-2 ml-auto md:mr-0 mr-auto m w-fit flex text-[14px] font-[400]' to='#'>Login <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.75 6.50005C0.75 6.85097 1.01077 7.14098 1.3491 7.18688L1.44316 7.19321L15.3064 7.19321C15.6892 7.19321 15.9996 6.88287 15.9996 6.50005C15.9996 6.14913 15.7388 5.85911 15.4005 5.81321L15.3064 5.80688L1.44316 5.80688C1.06034 5.80688 0.75 6.11722 0.75 6.50005Z" fill="#2B2B2B" />
                        <path d="M9.22605 11.5769C8.95477 11.847 8.95383 12.2859 9.22394 12.5571C9.4695 12.8038 9.85454 12.827 10.1263 12.6262L10.2042 12.5593L15.7957 6.99177C16.0431 6.74548 16.0656 6.35906 15.8632 6.08727L15.7958 6.00943L10.2043 0.441023C9.933 0.170887 9.49412 0.171795 9.22398 0.443052C8.9784 0.689649 8.95683 1.07478 9.15874 1.34573L9.22601 1.42333L14.324 6.50086L9.22605 11.5769Z" fill="#2B2B2B" />
                    </svg>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default EnterDetailForm
