import React, { useState } from 'react'
import ProgressHeadBar from '../Payment-component/ProgressHeadBar'
import SelectPayment from '../Payment-component/SelectPayment'
import PaymentDetailCard from '../Payment-component/PaymentDetailCard'
import SignupPopup from '../Payment-component/SignupPopup'
import PaymentModal from '../components/PaymentModal'
import { GlobalContext, initialStateType } from '../globalContext'
import { NavLink } from 'react-router-dom'


const PaymentPage = () => {
  const state: initialStateType = React.useContext(GlobalContext)?.state;
  const { reservation } = state;
  const [showModal, setShowModal] = useState(true)
  console.log(reservation);

  if (!reservation.airport || !reservation.partner) return <div className='payment-placeholder'><h1>Please select valid details for booking.</h1> <NavLink to='/'>Book Parking Lot Now</NavLink></div>
  return (
    <PaymentModal />
  )
}

export default PaymentPage
