import React, { useState } from 'react'
import CancelationFormModal from './CancelationFormModal'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Reservation } from '../types/types';
import { fetchReservation } from '../service/UserApi';
import Spinner from "../components/Spinner";

const CancelReservations = () => {

  const [showCancel, setShowCancel] = useState(false)
  const [reservationId, setReservationId] = useState('')
  const [reservationFound, setReservationFound] = useState(false)
  const [loader, setLoader] = useState(false)
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const schema = yup
    .object({
      reservationId: yup.string().required(),
      lastName: yup.string().required(),
    })
    .required();


  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data) => {
    setLoader(true)
    let resId: string = data?.reservationId
    if (resId) resId = resId.toLowerCase();
    if (resId.startsWith('pm-')) resId = resId.replace('pm-', '')
    if (resId.startsWith('pm')) resId = resId.replace('pm', '')
    try {
      const response: Reservation = await fetchReservation(resId)

      if (response.user_name.toLowerCase().includes(data.lastName.toLowerCase())) {
        setShowCancel(true);
        setReservation(response)
        setLoader(false)

      } else {
        alert('Invalid reservation details')
        setLoader(false)
      }
    } catch (error) {
      alert('Reservation not found!')
      setLoader(false)
    }

  };


  return (
    <>
      {showCancel && reservation &&
        <CancelationFormModal details={reservation} setShowCancel={setShowCancel} />
      }
      <div className="bg-[#FFFFFF] border border-[#D9D9D9] p-6 fader">
        <h4 className='text-[#2B2B2B] text-[24px] clash'>Cancel Reservation</h4>
        <p className='text-[#707070] text-[12px]'>*Mandatory field</p>


        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 mt-6">
          <input
            {...register("reservationId")}
            className='border border-[#E8E8E8] p-4 rounded-[10px] bg-[#FAFAFA]' placeholder='Reservation ID' />
          <input
            {...register("lastName")}
            className='border border-[#E8E8E8] p-4 rounded-[10px] bg-[#FAFAFA]' placeholder='Last name of Reserved by' />

          {reservationFound &&
            <p className='text-[20px] mb-[-20px] text-[#2EABE3]'>Reservation Not Found</p>
          }


          <button

            type='submit' className='py-4 px-1 w-full text-center flex justify-center items-center bg-[#2EABE3] text-[white] rounded-[10px] mt-2'>
            {loader ? <Spinner /> : 'Cancel'}
          </button>
        </form>
      </div>
    </>
  )
}

export default CancelReservations
