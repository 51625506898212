import React, { useState } from "react";
import bannerImg from "../assets/images/banner-img.jpg";
import { Url } from "uppy";
import SearchBox from "./SearchBox";


const BannerSec = () => {
  const [isModalOpen , setIsModalOpen] = useState(false)


  return (
    <section
      className="banner"
      style={{ backgroundImage: `url(${bannerImg})` }}
    >
      <div className="container mx-auto">
        <div className="banner-content">
          <h1>
            <b>Airport</b> Parking from 
            <br />
            as low as <b>$4.95/day</b>
          </h1>
          <span className="banner-tag-line">
            Over <b>35 million</b> cars parked with us
          </span>
         
          <SearchBox page={undefined} />
        </div>
      </div>
    </section>
  );
};

export default BannerSec;
