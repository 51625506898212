import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CustomModal.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { TimePicker } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ONLY_TIME_FORMAT } from '../../../utils/utils.ts';
import moment from 'moment';
import ListTimePicker from '../../components/ListTimePicker';

dayjs.extend(customParseFormat);

export type DateElements = {
  checkIn: string;
  checkOut: string;
};

type DateTimePickerModalProps = {
  onClosePaymentModal: () => void;
  onAllSelected: (date: DateElements) => void;
  onCheckinSelected: (checkin: string) => void
  onCheckoutSelected: (checkout: string) => void
  selectedField: 'checkin' | 'checkout' | ''
  setFieldFocus: (fieldName: 'checkin' | 'checkout' | '') => void
};

const DateTimePickerModal = (props: DateTimePickerModalProps) => {
  const { onClosePaymentModal, onAllSelected, selectedField, onCheckinSelected, onCheckoutSelected, setFieldFocus } = props;

  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [checkInTime, setCheckInTime] = useState<string | null>(null);
  const [checkOutTime, setCheckOutTime] = useState<string | null>(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isCheckinReset, setIsCheckinResets] = useState<boolean>(false);
  const [isShaking, setIsShaking] = useState<boolean>(false);
  const [checkInError, setCheckInError] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const checkIn = urlParams.get('checkIn');
    const checkOut = urlParams.get('checkOut');

    if (checkIn && checkOut) {
      const checkInDateTime = dayjs(checkIn, 'YYYY-MM-DD hh:mm A');
      const checkOutDateTime = dayjs(checkOut, 'YYYY-MM-DD hh:mm A');

      setCheckInDate(checkInDateTime.toDate());
      setCheckOutDate(checkOutDateTime.toDate());
      setCheckInTime(checkInDateTime.format(ONLY_TIME_FORMAT));
      setCheckOutTime(checkOutDateTime.format(ONLY_TIME_FORMAT));
    }
  }, []);

  const handleCheckInDateChange = (date: Date | null) => {
    setCheckInDate(date);
    setCheckInTime(null);
    if (checkOutDate && date && dayjs(date).isAfter(checkOutDate)) {
      setCheckOutDate(null);
      setCheckOutTime(null);
    }
    setFieldFocus('checkin');
  };

  const handleCheckOutDateChange = (date: Date | null) => {
    if (checkInDate && checkInTime) {
      const checkInDateTime = dayjs(checkInDate).set('hour', parseInt(checkInTime.split(':')[0])).set('minute', parseInt(checkInTime.split(':')[1]));
      if (date && dayjs(date).isBefore(checkInDateTime)) {
        setCheckInError('Check-out date and time must be after check-in date and time');
      } else {
        setCheckOutDate(date);
        setCheckOutTime(null);
        setCheckInError(null);
      }
    } else {
      setCheckInError('Please select Check-in Date and Time first');
    }
  };

  useEffect(() => {
    // Your existing useEffect logic here
  }, [checkInTime])

  const onCheckInTimeChange = (time: dayjs.Dayjs | null) => {
    const selectedTime = time ? time.format(ONLY_TIME_FORMAT) : null

    if (checkInDate) {
      setCheckInTime(selectedTime);
      onCheckinSelected(`${moment(checkInDate).format('YYYY-MM-DD')} ${selectedTime}`)
      setIsUpdated(true)
      setCheckOutDate(checkOutDate ?? dayjs(checkInDate).add(1, 'day').toDate())
      setFieldFocus('checkout')
    } else {
      alert('Please select Check-in Date first')
    }
  };

  const onCheckOutTimeChange = (time: dayjs.Dayjs | null) => {
    const selectedTime = time ? time.format(ONLY_TIME_FORMAT) : null

    if (checkOutDate) {
      if (checkInDate && checkInTime) {
        const checkInDateTime = dayjs(checkInDate).set('hour', parseInt(checkInTime.split(':')[0])).set('minute', parseInt(checkInTime.split(':')[1]));
        const checkOutDateTime = dayjs(checkOutDate).set('hour', time?.hour() || 0).set('minute', time?.minute() || 0);

        if (checkOutDateTime.isBefore(checkInDateTime)) {
          setCheckInError('Check-out date and time must be after check-in date and time');
        } else {
          setCheckOutTime(selectedTime);
          onCheckoutSelected(`${moment(checkOutDate).format('YYYY-MM-DD')} ${selectedTime}`)
          setIsUpdated(true)
          setCheckInError(null);
        }
      } else {
        setCheckInError('Please select Check-in Date and Time first');
      }
    } else {
      setCheckInError('Please select Check-out Date first');
    }
  };

  const isFormValid = checkInDate && checkOutDate && checkInTime && checkOutTime;
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  useEffect(() => {
    if (isUpdated) onConfirm()
  }, [checkInDate, checkOutDate, checkInTime, checkOutTime])

  const onConfirm = () => {
    if (isFormValid) {
      const urlParamsCurrent = new URLSearchParams(window.location.search);

      const selectedData: DateElements = {
        checkIn: `${moment(checkInDate).format('YYYY-MM-DD')} ${checkInTime}`,
        checkOut: `${moment(checkOutDate).format('YYYY-MM-DD')} ${checkOutTime}`,
      };
      const airportId = urlParamsCurrent.get('airport_id')
      if (airportId) { selectedData['airport_id'] = airportId }
      onAllSelected && onAllSelected(selectedData);
      const urlParams = new URLSearchParams(selectedData).toString();
      console.log(urlParams);

      window.history.pushState(null, '', `?${urlParams}`);
      onClosePaymentModal();
    }
  };
  let shoudLimitCheckout = false
  if (checkInDate && checkOutDate && checkInDate.getDate() === checkOutDate.getDate()) {
    shoudLimitCheckout = true
  }
  return (
    <div className={`time-modal-holder ${isShaking ? 'shake' : ''}  ${selectedField == 'checkout' ? 'slide-right' : ''}`} style={{ zIndex: '999' }}>

      <div className="modal-outer" onClick={() => {
        onClosePaymentModal(false)
      }}></div>
      <div className="dt-modal modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[100%] w-[100%] mx-auto relative shadow-xl">
        <button className="absolute top-[-35px] right-[0px] block md:hidden" onClick={onClosePaymentModal}>
          <svg width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM20.375 20.375C20.2594 20.4909 20.122 20.5828 19.9708 20.6455C19.8196 20.7083 19.6575 20.7406 19.4937 20.7406C19.33 20.7406 19.1679 20.7083 19.0167 20.6455C18.8655 20.5828 18.7281 20.4909 18.6125 20.375L15 16.7625L11.3875 20.375C11.1538 20.6087 10.8368 20.74 10.5062 20.74C10.1757 20.74 9.85872 20.6087 9.625 20.375C9.39128 20.1413 9.25997 19.8243 9.25997 19.4937C9.25997 19.3301 9.29221 19.168 9.35484 19.0168C9.41747 18.8656 9.50927 18.7282 9.625 18.6125L13.2375 15L9.625 11.3875C9.39128 11.1538 9.25997 10.8368 9.25997 10.5062C9.25997 10.1757 9.39128 9.85872 9.625 9.625C9.85872 9.39128 10.1757 9.25997 10.5062 9.25997C10.8368 9.25997 11.1538 9.39128 11.3875 9.625L15 13.2375L18.6125 9.625C18.7282 9.50927 18.8656 9.41747 19.0168 9.35484C19.168 9.29221 19.3301 9.25997 19.4937 9.25997C19.6574 9.25997 19.8195 9.29221 19.9707 9.35484C20.1219 9.41747 20.2593 9.50927 20.375 9.625C20.4907 9.74073 20.5825 9.87812 20.6452 10.0293C20.7078 10.1805 20.74 10.3426 20.74 10.5062C20.74 10.6699 20.7078 10.832 20.6452 10.9832C20.5825 11.1344 20.4907 11.2718 20.375 11.3875L16.7625 15L20.375 18.6125C20.85 19.0875 20.85 19.8875 20.375 20.375Z" fill="#fff"></path>
          </svg>
        </button>
        <div className="dt-grid">
          <div className="dt-col1 flex md:gap-4 gap-2 w-full">
            {selectedField == 'checkin' && <Calendar
              onChange={handleCheckInDateChange}
              minDate={new Date()}
              value={checkInDate}
              className="custom-calendar"
            />}
            {selectedField == 'checkout' && checkInDate != null && checkInTime != null && <Calendar
              onChange={handleCheckOutDateChange}
              minDate={checkInDate ? dayjs(checkInDate).add(1, 'day').toDate() : new Date()}
              value={checkOutDate ?? dayjs(checkInDate).add(1, 'day').toDate()}
              className="custom-calendar"
            />}

            {selectedField == 'checkin' && <div className='timer-selector-holder relative'>
              <p>Check-in time</p>
              <ListTimePicker
                checkInDate={checkInDate == null ? null : dayjs(checkInDate)}
                defaultDate={checkInTime ? dayjs(checkInTime, ONLY_TIME_FORMAT) : undefined}
                onTimeSelect={onCheckInTimeChange} /></div>}
            {selectedField == 'checkout' && checkInDate != null && checkInTime != null && <div className='timer-selector-holder relative'>
              <p>Checkout time</p>
              <ListTimePicker
                minTime={(checkInTime && shoudLimitCheckout) ? dayjs(checkInTime, ONLY_TIME_FORMAT).add(1, 'hour') : undefined}
                defaultDate={checkOutTime ? dayjs(checkOutTime, ONLY_TIME_FORMAT) : undefined}
                onTimeSelect={onCheckOutTimeChange} /></div>}

          </div>
        </div>
        {checkInError && <p className="text-[#ee4c4c] text-[12px] mt-2">{checkInError}</p>}
        <p className={`${isCheckinReset ? '' : 'opacity-0'} transition-[2s] text-[#ff6b81] `}>Please Select Check-in date again</p>
        <p style={{ fontSize: 10, alignSelf: 'center', display: 'flex', color: '#2B2B2B', marginTop: 0 }}>Checkout time must be atleast 1 hour after the checkin time</p>
        <div className="grid grid-cols-[160px_1fr] gap-[10px] md:pt-[15px] pt-[5px] ">
          <button
            className="bg-[#d9d9d9] rounded-[10px] text-center md:py-3 py-2 text-[#2B2B2B] w-full md:text-[16px] text-[12px] md:mt-4 mt-2"
            type="reset"
            onClick={onClosePaymentModal}
          >
            Cancel
          </button>
        </div>

      </div>
    </div >
  );
};

export default DateTimePickerModal;