import React from "react";

import AuthProvider from "./AuthContext";
import GlobalProvider from "./globalContext";
import Main from "./main";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


// import WOW from 'wow.js';

function App() {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: 'acct_1EvVusHvHB9LjwtD_secret_sk_test_WyTBrMPYTQR424ouD9yYyuLu',
  // };
  return (
    <AuthProvider>
      <GlobalProvider>
        <Router>
          <Main />
          {/* <Elements stripe={stripePromise} >
            <Main />
          </Elements> */}

        </Router>
      </GlobalProvider>
    </AuthProvider>
  );
}

export default App;
