import React, { useState } from 'react'
import { PartnerInfo } from "../types/types";
import moment from 'moment';

type OpeningHoursProps = {
    partner: PartnerInfo
};

const OpeningHours = ({ partner }: OpeningHoursProps) => {

    type Days = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'

    const business_info = partner?.business_info;

    const formatTiming = (day: Days) => {
        if (business_info) {
            const startDate = business_info[day + '_start_time']
            const endDate = business_info[day + '_end_time']
            return moment(startDate, 'HH:mm:ss').format('hh:mm A') + ' - ' + moment(endDate, 'HH:mm:ss').format('hh:mm A')
        }

    }
    const currentDay = moment().format('dddd').toLowerCase(); // Get the current day in lowercase (e.g., 'monday')

    const daysOfWeek: Days[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    return (

        <>

            <div className="">
                <div className="w-full mb-3 p-3 rounded-[4px]">
                    <h5 className='text-[#2B2B2B] text-[16px] font-[500]'>Operating Hours</h5>
                    <ul className='day-list-holder'>
                        {daysOfWeek.map((day) => (
                            <>
                                <li key={day} className={day === currentDay ? 'sat' : ''}>
                                    <span>{day.charAt(0).toUpperCase() + day.slice(1)}</span>

                                </li>
                                <li className={day === currentDay ? 'sat' : ''} > <span>{formatTiming(day)}</span></li></>
                        ))}

                    </ul>
                </div>
                {/* <div className="hidden lg:block">
                    <ReserveParkingBtn showPackageModal={setShowVehicalModal} />
                </div> */}
            </div>
        </>
    )
}

export default OpeningHours
