import React from 'react'

const InputField = ({ type, label }) => {
    return (
        <div className="input-box-holder">
            <input type={type} placeholder='' />
            <label>{label}</label>
        </div>
    )
}

export default InputField
