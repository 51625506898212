import React from "react";
import Logo from "../assets/images/logo.svg";
import { GlobalContext } from "../globalContext";
import { Reservation } from "../types/types";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/utils.ts";
import moment from "moment";

type ReservationDetailsCardProps = {
  details:Reservation
}
const ReservationDetailsCard = ({details}:ReservationDetailsCardProps) => {
  const partner = details?.partner
  const days = getDaysBetweenDates(details?.check_in,details?.check_out)


  return (
    <div className=" md:grid-cols-2 grid-cols-1 md:gap-6 gap-10 mt-7 grid ">
      <div className="block bg-[white] shadow-lg p-3 rounded-[4px]">
        <span className="bg-[#2eaae341] uppercase py-2 px-4 text-[12px] text-[#2EABE3] rounded-[10px] mt-[-30px] block w-fit">
          Parking lot
        </span>
        <img className="max-w-[100px] w-full ml-auto" src={Logo} alt="" />
        <h3 className="text-[#2B2B2B] text-[16px]">{details?.partner?.name}</h3>
        <p className="text-[#707070] text-[12px]">
          
          {partner?.address}
        </p>
      </div>
      <div className="block bg-[white] shadow-lg p-3 rounded-[4px]">
        <span className="bg-[#2eaae341] uppercase py-2 px-4 text-[12px] text-[#2EABE3] rounded-[10px] mt-[-30px] block w-fit">
          Dates & Options
        </span>
        <h5 className="text-[#2EABE3] text-[12px] text-right">
          Reservation for {days} days
        </h5>
        <p className="text-[#707070] text-[10px]">Person Parking:</p>
        <h4 className="text-[#2B2B2B] text-[12px] font-[400]">{details?.user_name}</h4>
        <div className="grid grid-cols-2 mt-3">
          <div className="flex flex-col ">
            <p className="text-[#707070] text-[10px]">Check in</p>
            <p className="text-[#2B2B2B] text-[12px]">{moment(details?.check_in).format(DATE_FORMAT)}</p>
          </div>
          <div className="flex flex-col ">
            <p className="text-[#707070] text-[10px]">Check out</p>
            <p className="text-[#2B2B2B] text-[12px]">{moment(details?.check_out).format(DATE_FORMAT)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetailsCard;
