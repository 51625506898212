import moment from "moment";
import { PartnerInfo } from "../src/types/types";

export function isValidDate(currentDate, workingData) {
  if (!moment.isMoment(currentDate)) {
    return false;
  }

  const dayName = currentDate.format("dddd").toLowerCase(); // e.g., "monday"
  const cDateStr = currentDate.format("YYYY-MM-DD");
  const startTime = moment(
    cDateStr + " " + workingData[`${dayName}_start_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const endTime = moment(
    cDateStr + " " + workingData[`${dayName}_end_time`],
    "YYYY-MM-DD HH:mm:ss"
  );
  const now = moment();
  if (currentDate.isSame(now, "day") && currentDate.isBefore(now)) {
    return false;
  }
  return currentDate.isBetween(startTime, endTime, null, "[]");
}
export function checkHourDIffrence(checkInDate, checkOutDate) {
  if (!moment.isMoment(checkInDate) || !moment.isMoment(checkOutDate)) {
    return false; // Invalid dates
  }

  const difference = moment.duration(checkOutDate.diff(checkInDate));
  const hoursDiff = difference.asHours();

  return hoursDiff >= 1;
}
export const ONLY_DATE_FORMAT = "MM-DD-YYYY";
export const ONLY_TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = ONLY_DATE_FORMAT + " " + ONLY_TIME_FORMAT;

export const getDateFromString = (strDate) => {
  return moment(strDate, DATE_FORMAT);
};
export function getDatesBetween(startDate, eDate) {
  const dates = [];
  // alert(JSON.stringify({startDate,eDate}))
  // const currentDate = moment(startDate, DATE_FORMAT);
  // const endDate = moment(eDate, DATE_FORMAT);
  const currentDate = moment(startDate, ONLY_DATE_FORMAT+' 00:00:00');
  const endDate = moment(eDate,  ONLY_DATE_FORMAT+' 00:00:00');
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.clone().toDate()); // Clone to create new objects
    currentDate.add(1, "days");
  }

  return dates;
}

export function getDaysBetweenDates(startDate, endDate) {
  // Parse the dates and set the time to midnight
  let shouldParse = false;
  if (startDate.includes("am") || startDate.includes("AM")) {
    shouldParse = true;
  }
  let start = shouldParse
    ? moment(startDate, "YYYY-MM-DD hh:mm A").startOf("day")
    : moment(startDate).startOf("day");
  let end = shouldParse
    ? moment(endDate, "YYYY-MM-DD hh:mm A").startOf("day")
    : moment(endDate).startOf("day");
  // let end = moment(endDate).startOf("day");
  console.log(start, end);
  // Calculate the difference in days
  const diffDays = end.diff(start, "days");

  return diffDays + 1;
}

export const getStartDateOfCurrentMonth = () => {
  return moment().startOf("month").format("YYYY-MM-DD");
};

export const getStartDateOfCurrentWeek = () => {
  return moment().startOf("isoWeek").format("YYYY-MM-DD");
};
export const getValidOptions = (
  partner: PartnerInfo
): PartnerOptions | undefined => {
  try {
    if (partner?.options != null) {
      const optionJson: PartnerOptions = JSON.parse(partner?.options);
      return optionJson;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const checkifPartnerHasValidTime = (
  checkInTime: string,
  checkOutTime: string,
  partner: PartnerInfo
) => {
  if (checkInTime && checkOutTime) {
  
    
    const checkInDateTime = moment(checkInTime);
    const checkOutDateTime = moment(checkOutTime);

    const businessHours = partner?.business_info;
    if (businessHours) {
      const checkInDay = checkInDateTime.format("dddd").toLowerCase();
      const checkOutDay = checkOutDateTime.format("dddd").toLowerCase();
      const cnTime = moment(checkInTime).format("YYYY-MM-DD");
      const coTime = moment(checkOutTime).format("YYYY-MM-DD");

      const checkInStart = moment(
        `${checkInDateTime.format("YYYY-MM-DD")} ${
          businessHours[`${checkInDay}_start_time`]
        }`
      );
      const checkInEnd = moment(
        `${checkInDateTime.format("YYYY-MM-DD")} ${
          businessHours[`${checkInDay}_end_time`]
        }`
      );
      const checkOutStart = moment(
        `${checkOutDateTime.format("YYYY-MM-DD")} ${
          businessHours[`${checkOutDay}_start_time`]
        }`
      );
      const checkOutEnd = moment(
        `${checkOutDateTime.format("YYYY-MM-DD")} ${
          businessHours[`${checkOutDay}_end_time`]
        }`
      );

      const checkInTimeValid = checkInDateTime.isBetween(
        checkInStart,
        checkInEnd,
        null,
        "[)"
      );
      const checkOutTimeValid = checkOutDateTime.isBetween(
        checkOutStart,
        checkOutEnd,
        null,
        "[)"
      );

      if (!checkInTimeValid || !checkOutTimeValid) {
        let errorMessages = [];

        if (!checkInTimeValid) {
          errorMessages.push(
            `Check-in time should be between ${checkInStart.format(
              "hh:mm A"
            )} and ${checkInEnd.format("hh:mm A")} on ${
              checkInDay.charAt(0).toUpperCase() + checkInDay.slice(1)
            }.`
          );
        }

        if (!checkOutTimeValid) {
          errorMessages.push(
            `Check-out time should be between ${checkOutStart.format(
              "hh:mm A"
            )} and ${checkOutEnd.format("hh:mm A")} on ${
              checkOutDay.charAt(0).toUpperCase() + checkOutDay.slice(1)
            }.`
          );
        }

        return errorMessages.join(" ");
      } else {
       
        return '';
      }
    }
  }
};
