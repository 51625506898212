import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from 'react-router-dom';
import { cancleReservation, fetchPartners } from '../service/UserApi'
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerModal from '../home-components/CustomModal/DateTimePickerModal';
import { GlobalContext, showToast } from "../globalContext";
import reservationActions from "../actions/ReservationActions";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/utils.ts";
import { Reservation } from "../types/types";
import moment from "moment";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../components/Spinner";

type CancelationFormModalProps = {
    setShowCancel: (show: boolean) => void
    details: Reservation
}
const CancelationFormModal = ({ setShowCancel, details }: CancelationFormModalProps) => {

    const { dispatch } = React.useContext(GlobalContext);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [hasCancelled, setHasCancelled] = useState(false)






    const shouldAllowCancel = (checkin: string) => {


        // Example usage within a function
        const currentDateTime = moment(); // Current date and time
        const checkInDateTime = moment(checkin); // Convert details?.check_in to moment object

        if (checkInDateTime.isBefore(currentDateTime)) {
            //    return {allow:false,message:"You can not cancel the booking after the check in time"}
            return { allow: false, message: "You can not cancel the booking after the check in time" }
        } else if (checkInDateTime.isSame(currentDateTime)) {
            return { allow: false, message: "You can not cancel the booking after the check in time" }
        } else {
            return { allow: true, message: "" }
        }
    }


    const CheckInComponent = () => (
        <p className="text-[#2B2B2B] text-[16px] md:w-[200px] w-full check-date-text" style={{ color: '#2B2B2B !important' }} >
            {moment(details?.check_in).format(DATE_FORMAT) ?? 'Select Check-in'}
        </p>
    );

    const ChecoutComponent = () => (
        <p className="text-[#2B2B2B] text-[16px] md:w-[200px] w-full check-date-text" style={{ color: '#2B2B2B !important' }}>
            {moment(details?.check_out).format(DATE_FORMAT) ?? 'Select Check-out'}
        </p>
    );


    const schema = yup
        .object({

            reason: yup.string().required(),
        })
        .required();

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });


    const onSubmit = async (data) => {
        setLoading(true)
        const resp = await cancleReservation(details, data.reason)
        if (resp.reservation_id && resp.id) {
            showToast(dispatch, "Cancellation request sent!");
            setLoading(false)
            // setShowCancel(false)
            setHasCancelled(true)

        } else {
            alert('Somting went wrong!')
            setLoading(false)
        }
    };




    const { allow, message } = shouldAllowCancel(details?.check_in)
    return (
        <div className="modal-holder">

            <div className="modal-outer" onClick={() => { setShowCancel(false) }}></div>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[600px] w-full mx-auto">
                <div className="flex justify-between items-center">
                    <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4'>
                        Are you sure?<br />
                        Do you want to cancel your reservation?
                    </h4>
                    <button
                        onClick={() => { setShowCancel(false) }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM20.375 20.375C20.2594 20.4909 20.122 20.5828 19.9708 20.6455C19.8196 20.7083 19.6575 20.7406 19.4937 20.7406C19.33 20.7406 19.1679 20.7083 19.0167 20.6455C18.8655 20.5828 18.7281 20.4909 18.6125 20.375L15 16.7625L11.3875 20.375C11.1538 20.6087 10.8368 20.74 10.5062 20.74C10.1757 20.74 9.85872 20.6087 9.625 20.375C9.39128 20.1413 9.25997 19.8243 9.25997 19.4937C9.25997 19.3301 9.29221 19.168 9.35484 19.0168C9.41747 18.8656 9.50927 18.7282 9.625 18.6125L13.2375 15L9.625 11.3875C9.39128 11.1538 9.25997 10.8368 9.25997 10.5062C9.25997 10.1757 9.39128 9.85872 9.625 9.625C9.85872 9.39128 10.1757 9.25997 10.5062 9.25997C10.8368 9.25997 11.1538 9.39128 11.3875 9.625L15 13.2375L18.6125 9.625C18.7282 9.50927 18.8656 9.41747 19.0168 9.35484C19.168 9.29221 19.3301 9.25997 19.4937 9.25997C19.6574 9.25997 19.8195 9.29221 19.9707 9.35484C20.1219 9.41747 20.2593 9.50927 20.375 9.625C20.4907 9.74073 20.5825 9.87812 20.6452 10.0293C20.7078 10.1805 20.74 10.3426 20.74 10.5062C20.74 10.6699 20.7078 10.832 20.6452 10.9832C20.5825 11.1344 20.4907 11.2718 20.375 11.3875L16.7625 15L20.375 18.6125C20.85 19.0875 20.85 19.8875 20.375 20.375Z" fill="#B8B6B6" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col gap-4 add-partner-fields-holder">
                    <div className="popup-field-box">
                        <label>Parking lot name</label>
                        {/* <input
                            {...register("parking_lot")}
                            type='text' placeholder='Enter Parking Lot name' /> */}
                        <h5>{details?.partner?.name}</h5>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-2 gap-4 mt-2">
                        <div className="popup-field-box" >
                            <div className="checkin-box" style={{ border: 'none !important', }}>
                                <p>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    Check in
                                </p>
                                <CheckInComponent />
                            </div>
                        </div>


                        <div className="checkin-box">
                            <p>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                Check out
                            </p>
                            <ChecoutComponent />
                        </div>
                    </div>

                    <div className="popup-field-box">
                        <label>Reason</label>
                        <input
                            {...register("reason")}
                            type='text' placeholder='Enter reason for cancelation' />
                    </div>



                </div>

                <div className="flex justify-between items-center mt-6 pt-6 border-t border-[#C4C4C4]">
                    <p className='text-[#2B2B2B] text-[16px]'>Total you paid</p>
                    <p className='clash text-[#2B2B2B] text-[16px] font-[500]'>${(Number(details?.total) - Number(details?.due_now)).toFixed(2)}</p>
                </div>
{hasCancelled &&
                <p className='text-center  text-[12px] text-[#2EABE3] mt-4'>“Your cancellation has been processed! A refund will be issued if applicable.”</p>
}

                {!allow && <p>{message}</p>}
                <div className="flex items-center gap-4">
                    <button
                        onClick={() => { setShowCancel(false) }}
                        className='w-[120px] bg-[#EEEEEE] rounded-[10px] font-[600] text-center py-3 text-[#2B2B2B] w-full text-[16px] mt-4' type='submit'>
                        Back
                    </button>

                    {loading && <div className='bg-[#2EABE3] rounded-[10px] text-center py-3 text-[white] w-full text-[16px] mt-4'>
                        <center>  <Spinner /></center>
                    </div>}
 
                    {!loading &&  <button disabled={loading} className={`bg-[#2EABE3] ${allow ? "" : 'disabled'} ${hasCancelled? 'hidden' : ''} rounded-[10px] text-center  py-3 text-[white] w-full text-[16px] mt-4`} type='submit'>
                        <span>  Cancel Reservation </span>
                    </button>}
                
                </div>


                <p className='text-[#2B2B2B] text-[12px] text-center mt-5 pt-5  border-t border-[#C4C4C4]'>If you have any questions or concerns, contact us, email us at <NavLink to='#'>info@parkingmaniac.com</NavLink></p>
            </form>
        </div>
    )
}

export default CancelationFormModal
