import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import EditReservationModal from "./EditReservationModal";
import { fetchReservation } from "../service/UserApi";
import { Reservation } from "../types/types";
import Spinner from "../components/Spinner";

const EditReservation = () => {
  const [showCancel, setShowCancel] = useState(false);
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [loader, setLoader] = useState(false)
  const schema = yup
    .object({
      reservationId: yup.string().required(),
      lastName: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoader(true)
    const response: Reservation = await fetchReservation(data?.reservationId)
    if (response.user_name.includes(data.lastName)) {
      setShowCancel(true);
      setReservation(response)
      reset()
      setLoader(false)
    } else {
      alert('Invalid reservation details')
      reset()
    }

  };

  return (
    <>
      {reservation && showCancel && <EditReservationModal details={reservation} setShowCancel={()=>{
        setShowCancel(false)
      }} />}
      <div className="bg-[#FFFFFF] border border-[#D9D9D9] p-6 fader">
        <h4 className="text-[#2B2B2B] text-[24px] clash">Edit Reservation</h4>
        <p className="text-[#707070] text-[12px]">*Mandatory field</p>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 mt-6"
        >
          <input
            {...register("reservationId")}
            className="border border-[#E8E8E8] p-4 rounded-[10px] bg-[#FAFAFA]"
            placeholder="Reservation ID"
          />
          <input
            {...register("lastName")}
            className="border border-[#E8E8E8] p-4 rounded-[10px] bg-[#FAFAFA]"
            placeholder="Last name of Reserved by"
          />

          <button
            type="submit"
            className="py-4 px-1 flex justify-center  w-full text-center bg-[#2EABE3] text-[white] rounded-[10px] mt-2"
          >
           {loader? <Spinner/> : 'Go to edit' } 
          </button>
        </form>
      </div>
    </>
  );
};

export default EditReservation;
