import React from "react";
import RatingStarsBar from "./RatingStarsBar";
import ParkingReviewsBox from "./ParkingReviewsBox";
import { PartnerInfo } from "../types/types";
import moment from "moment";
type ParkingReviewsHolderProps = {
  partner: PartnerInfo
}
const ParkingReviewsHolder = ({ partner }: ParkingReviewsHolderProps) => {
  let reviews = partner?.reviews.reverse();
  reviews = reviews.sort((a, b) => new Date(b.date) - new Date(a.date));
  return (
    <>
      <div className="lg:px-10 px-5">
        <div className="flex items-center justify-between mb-2">
          <h4 className="text-[#2B2B2B] text-[16px] font-[400]">
            Reviews<span className="text-[12px]"> ( {Number(partner.average_rating).toFixed(1)})</span>
          </h4>
          <RatingStarsBar rating={partner?.average_rating} />
        </div>
        <div className="flex flex-col gap-2 mb-5">
          {reviews?.map((item) => {
            return <ParkingReviewsBox
              RevName={item?.username}
              RevDesc={item?.message}
              RevDate={moment(item?.date).format("DD MMM YYYY")}

              RatingStars={item?.stars}
            />
          })}

        </div>
      </div>
    </>
  );
};

export default ParkingReviewsHolder;
