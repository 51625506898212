import React from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PublicHeader from "./components/PublicHeader";
import SnackBar from "./components/SnackBar";
// import SnackBar from "./components/SnackBar";

import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Home";
import SearchAirport from "./pages/SearchAirport";
import Footer from "./home-components/Footer";
import CheckOutSignup from "./pages/CheckOutSignup";
import PaymentPage from "./pages/PaymentPage";
import CompletePage from "./pages/CompletePage";
import EditReservationPage from "./pages/EditReservationPage";
import CancelReservationPage from "./pages/CancelReservationPage";
import SearchReservationPage from "./pages/SearchReservationPage";
import Reservation from "./pages/Reservation";
import UpdateReservation from "./pages/UpdateReservation";
import ContactPage from "./pages/ContactPage";

import Refund from "./pages/Refund";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Lots from "./pages/ParkingLots";


import Privacy from "./pages/Privacy";
import ParkingTabContent from "./home-components/ParkingTabContent";

function renderHeader() {
  return <PublicHeader />;
}

function renderRoutes() {
  return (
    <Routes>
      <Route exact path="/search" element={<SearchAirport />}></Route>
      <Route path="/payment" exact element={<PaymentPage />}></Route>
      <Route path="/partner_details/:id" element={<ParkingTabContent />} />

      <Route
        exact
        path="/checkout_&_signup"
        element={<CheckOutSignup />}
      ></Route>
      <Route path="/" exact element={<Home />}></Route>
      <Route path="*" exact element={<NotFoundPage />}></Route>
      <Route
        path="/reservation_Complete"
        exact
        element={<CompletePage />}
      ></Route>
      <Route
        path="/update_reservation/:id"
        exact
        element={<UpdateReservation />}
      ></Route>
      <Route
        path="/edit_reservation"
        exact
        element={<EditReservationPage />}
      ></Route>
      <Route
        path="/cancel"
        exact
        element={<CancelReservationPage />}
      ></Route>
      <Route
        path="/search_reservation"
        exact
        element={<SearchReservationPage />}
      ></Route>
      <Route
        path="/contact"
        exact
        element={<ContactPage />}
      ></Route>
      <Route path="/refund" exact element={<Refund />}></Route>
      <Route path="/aboutus" exact element={<About />}></Route>
      <Route path="/our_lots" exact element={<Lots />}></Route>
      <Route path="/terms_&_condition" exact element={<Terms />}></Route>
      <Route path="/privacy_&_policy" exact element={<Privacy />}></Route>
      <Route path="/CompletePage" exact element={<CompletePage />}></Route>
      <Route path="/reservation/:id" exact element={<Reservation />}></Route>
    </Routes>
  );
}

function Main() {
  return (
    <div className="h-full w-full">
      <>
        {renderHeader()}
        {renderRoutes()}
        <Footer />
      </>

      <SnackBar />
    </div>
  );
}

export default Main;
