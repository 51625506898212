import React from 'react'
import moment from 'moment';
import { GlobalContext } from '../globalContext';
import { NavLink } from 'react-router-dom';

const Refund = () => {
    const currentDate = moment().format('MMMM D, YYYY'); // Format current date as desired

    const { dispatch, state } = React.useContext(GlobalContext);
    const { businessInfo } = state

    return (
        <div className="container mx-auto ">

            <div className="md:py-[70px] py-[40px]">
                <h3 className='md:text-[50px] text-[30px] font-[700] uppercase'>Refund Policy</h3>
                <div className="w-full key-box bg-[#EEEEEE] p-3 my-3">
                    <h3 className="flex items-center gap-1 text-[#2B2B2B] text-[16px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16797 14.166H10.8346V9.16601H9.16797V14.166ZM10.0013 7.49935C10.2374 7.49935 10.4355 7.41935 10.5955 7.25935C10.7555 7.09935 10.8352 6.90157 10.8346 6.66601C10.8346 6.4299 10.7546 6.23213 10.5946 6.07268C10.4346 5.91324 10.2369 5.83324 10.0013 5.83268C9.76519 5.83268 9.56741 5.91268 9.40797 6.07268C9.24852 6.23268 9.16852 6.43046 9.16797 6.66601C9.16797 6.90213 9.24797 7.10018 9.40797 7.26018C9.56797 7.42018 9.76575 7.4999 10.0013 7.49935ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z" fill="#2EABE3" />
                        </svg>
                        Refund Policy for parkingmaniac.com
                    </h3>
                    <p>
                        <strong>Effective Date:</strong> {currentDate}
                    </p>

                    {/* Introduction */}
                    <p>
                        At parkingmaniac.com, we strive to provide our customers with a seamless and satisfactory parking reservation experience. However, we understand that there may be circumstances where you need to cancel your reservation or request a refund. This Refund Policy outlines our guidelines for cancellations and refunds.
                    </p>

                    {/* Cancellation Policy */}
                    <p>
                        <strong>1. Cancellation Policy:</strong>
                    </p>
                    <ul>
                        <li>
                            You may cancel your parking reservation at any time before the scheduled start time of your reservation. To cancel, please log in to your parkingmaniac.com account and follow the cancellation instructions provided.
                        </li>
                        <li>
                            Cancellations made within a certain timeframe before the scheduled start time of your reservation may be eligible for a full or partial refund, as outlined below.
                        </li>
                    </ul>

                    {/* Refund Eligibility */}
                    <p>
                        <strong>2. Refund Eligibility:</strong>
                    </p>
                    <ul>
                        <li>
                            Refunds may be issued in the following circumstances:
                            <ul>
                                <li>Cancellations made within the specified cancellation window, as determined by the individual parking lot's policies.</li>
                                <li>If the parking lot is unable to accommodate your reservation due to unforeseen circumstances, such as overbooking or temporary closure.</li>
                            </ul>
                        </li>
                        <li>
                            Refunds will not be issued in the following circumstances:
                            <ul>
                                <li>No-shows or failure to cancel your reservation within the specified cancellation window.</li>
                                <li>If your reservation is terminated early due to violations of the parking lot's rules or policies.</li>
                            </ul>
                        </li>
                    </ul>

                    {/* Refund Process */}
                    <p>
                        <strong>3. Refund Process:</strong>
                    </p>
                    <ul>
                        <li>
                            If you are eligible for a refund according to the criteria outlined above, please contact our customer support team at [Insert Contact Information] to initiate the refund process.
                        </li>
                        <li>
                            Refunds will be issued to the original payment method used for the reservation, unless otherwise specified.
                        </li>
                        <li>
                            Please allow up to [Insert Number] business days for the refund to be processed and reflected in your account.
                        </li>
                    </ul>

                    {/* Changes to this Refund Policy */}
                    <p>
                        <strong>4. Changes to this Refund Policy:</strong>
                    </p>
                    <p>
                        We may update this Refund Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated Refund Policy on our website.
                    </p>

                    {/* Contact Us */}
                    <p>
                        <strong>5. Contact Us:</strong>
                    </p>
                    <p>
                        If you have any questions or concerns about this Refund Policy or your refund eligibility, please contact us at <NavLink to={`tel:${businessInfo?.phone_number}`} className="font-[800] inline-flex items-center gap-2">

Phone: {businessInfo?.phone_number} 
</NavLink>
<NavLink to={`tel:${businessInfo?.email}`} className="inline-flex items-center gap-2 ml-2 font-[800]">

 Email: {businessInfo?.email}
</NavLink>.
                    </p>

                    {/* Acknowledgment */}
                    <p>
                        By making a reservation with parkingmaniac.com, you acknowledge and agree to abide by the terms of this Refund Policy.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Refund
