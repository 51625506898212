import React, { useState } from 'react'
import { useEffect } from 'react'
import { fetchPartners } from '../service/UserApi'
import RatingStarsBar from '../search-page-component/RatingStarsBar'
import { NavLink } from 'react-router-dom'
import Spinner from '../components/Spinner'

const ParkingLots = () => {


    const [getPartnersData, setGetPartnersData] = useState([])
    const [lotLoading, setLotLoading] = useState(false)

    const getPartners = async () => {
        setLotLoading(true)
        try {

            const response = await fetchPartners();



            setGetPartnersData(response)// replace `tempPartners` with `response`
            setLotLoading(false)
        } catch (error) {

            setGetPartnersData([])
            setLotLoading(false)

        } finally {

        }
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,

        });
    };

    useEffect(() => {
        scrollToTop()
        getPartners()
    }, [])
    return (
        <>
            <section className="flex items-center justify-center md:py-[60px] py-[20px] lot-banner px-[15px]" >
                <h1 className=' md:text-[50px] text-[black] uppercase text-[30px] font-[900] text-center'>Our <span className='text-[#2eabe3] block'>Parking Lots</span></h1>
            </section>

            <section className='md:py-[80px] py-[40px]'>
                <div className="container mx-auto">
                    <h1 className='mb-4 md:text-[30px] text-[black] uppercase text-[20px] font-[900] text-center'>Top <span className='text-[#2eabe3]'>US</span> Parking Lots </h1>
                    {lotLoading ? 
                    <div className="flex justify-center">
                    <Spinner /> 
                    </div>
                    :
                    <>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5">
                        {getPartnersData.map((partner) => (
                            <div className="area-card relative border bg-[#FFFFFF] border-[#D9D9D9] rounded-[10px] w-full overflow-hidden" style={{ zIndex: '1' }}>
                                <div className="area-card relative border bg-[#FFFFFF] border-[#D9D9D9] rounded-[10px] w-full overflow-hidden" style={{ zIndex: '1' }}>
                                    <div className="flex flex-col gap-3 items-center md:p-2 p-1 w-full">
                                        <img
                                            className=" cursor-pointer max-w-[100%] max-h-[170px] w-full rounded-[5px] object-cover"
                                            src={partner.images[0].path}
                                            alt=""
                                        />
                                        <div className="flex flex-col gap-1 w-full">
                                            {partner.name && (
                                                <h2 className="text-[#2B2B2B] lg:text-[20px] text-[18px] font-[500]">
                                                    {partner.name}
                                                </h2>
                                            )}
                                            {partner.average_rating && (
                                                <RatingStarsBar showNumbers={true} rating={partner.average_rating} />
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div >
                        ))}
                    </div>

                    <div className="w-fit mx-auto mt-7">
                        <NavLink to='/' className='about-btn mx-auto block mt-4'>Reserve Parking Lot</NavLink>
                    </div>
                    </>
                    }
                </div>
            </section>

        </>
    )
}

export default ParkingLots
