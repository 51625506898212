import React from 'react'
import SignupEmailBox from './SignupEmailBox'
import { NavLink } from 'react-router-dom'

const NewsLetter = () => {
    return (
        <section className='news-letter-sec'>
            <div className="container mx-auto">
                <div className="why-parking-title mb-10">
                    <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
                    <p>Get discounts and participate in giveaways.</p>
                </div>
                <SignupEmailBox />
                <div className="news-sec-text">
                    <p>*You will receive news, promotions and discounts, don't worry, we are not spam! </p>
                    <p>By subscribing you accept our <NavLink to='/privacy_&_policy'> Privacy Policy </NavLink>to receive commercial communications from Parclick. Without any obligation, you can unsubscribe whenever you want in the same newsletter 😉</p>
                </div>
            </div>
        </section>
    )
}

export default NewsLetter
