import React from 'react'
import moment from 'moment';
import { GlobalContext } from '../globalContext';
import { NavLink } from 'react-router-dom';


const Privacy = () => {

    const currentDate = moment().format('MMMM D, YYYY');

    const { dispatch, state } = React.useContext(GlobalContext);
    const { businessInfo } = state

    return (
        <div className="container mx-auto ">

            <div className="md:py-[70px] py-[40px]">
                <h3 className='md:text-[50px] text-[30px] font-[700] uppercase'>Privacy Policy</h3>
                <div className="w-full key-box bg-[#EEEEEE] p-3 my-3">
                    <h3 className="flex items-center gap-1 text-[#2B2B2B] text-[16px]">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.16797 14.166H10.8346V9.16601H9.16797V14.166ZM10.0013 7.49935C10.2374 7.49935 10.4355 7.41935 10.5955 7.25935C10.7555 7.09935 10.8352 6.90157 10.8346 6.66601C10.8346 6.4299 10.7546 6.23213 10.5946 6.07268C10.4346 5.91324 10.2369 5.83324 10.0013 5.83268C9.76519 5.83268 9.56741 5.91268 9.40797 6.07268C9.24852 6.23268 9.16852 6.43046 9.16797 6.66601C9.16797 6.90213 9.24797 7.10018 9.40797 7.26018C9.56797 7.42018 9.76575 7.4999 10.0013 7.49935ZM10.0013 18.3327C8.84852 18.3327 7.76519 18.1138 6.7513 17.676C5.73741 17.2382 4.85547 16.6446 4.10547 15.8952C3.35547 15.1452 2.76186 14.2632 2.32464 13.2493C1.88741 12.2355 1.66852 11.1521 1.66797 9.99935C1.66797 8.84657 1.88686 7.76324 2.32464 6.74935C2.76241 5.73546 3.35602 4.85352 4.10547 4.10352C4.85547 3.35352 5.73741 2.7599 6.7513 2.32268C7.76519 1.88546 8.84852 1.66657 10.0013 1.66602C11.1541 1.66602 12.2374 1.8849 13.2513 2.32268C14.2652 2.76046 15.1471 3.35407 15.8971 4.10352C16.6471 4.85352 17.241 5.73546 17.6788 6.74935C18.1166 7.76324 18.3352 8.84657 18.3346 9.99935C18.3346 11.1521 18.1157 12.2355 17.678 13.2493C17.2402 14.2632 16.6466 15.1452 15.8971 15.8952C15.1471 16.6452 14.2652 17.2391 13.2513 17.6768C12.2374 18.1146 11.1541 18.3332 10.0013 18.3327Z" fill="#2EABE3" />
                        </svg>
                        Privacy Policy for parkingmaniac.com
                    </h3>
                    <p>
                        <strong>Effective Date:</strong> {currentDate}
                    </p>

                    {/* Introduction */}
                    <p>
                        At parkingmaniac.com, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website or use our services.

                    </p>

                    {/* Cancellation Policy */}
                    <p>
                        <strong>1. Information We Collect:</strong>
                    </p>
                    <ul>
                        <li>
                            Personal Information: When you use parkingmaniac.com, we may collect personal information such as your name, email address, phone number, and payment information. This information is necessary to facilitate your parking reservations and provide you with our services.

                        </li>
                        <li>
                            Usage Information: We may collect information about your interactions with our website, including the pages you visit, the links you click, and other actions you take.
                        </li>
                    </ul>

                    {/* Refund Eligibility */}
                    <p>
                        <strong>2. How We Use Your Information:</strong>
                    </p>
                    <ul>
                        <li>
                            Refunds may be issued in the following circumstances:
                        </li>
                        <li>
                            To Provide Services: We use your personal information to facilitate parking reservations and provide you with our services. This includes processing payments, sending confirmation emails, and providing customer support.

                        </li>
                        <li>
                            To Improve Our Services: We may use your usage information to analyze trends, track user interactions, and improve the usability of our website and services.

                        </li>
                        <li>
                            To Communicate With You: We may use your contact information to send you updates about your reservations, promotional offers, and other relevant information.

                        </li>


                    </ul>

                    {/* Refund Process */}
                    <p>
                        <strong>3. Sharing of Information:</strong>
                    </p>
                    <ul>
                        <li>
                            Partnered Parking Lots: parkingmaniac.com may share your personal information with our partnered parking lots in order to facilitate your reservations and provide you with parking services. However, we will only share the information necessary to fulfill these purposes, and we require our partners to adhere to this Privacy Policy and applicable privacy laws.

                        </li>
                        <li>
                            Legal Compliance: We may disclose your information in response to legal requests or inquiries, or to comply with applicable laws, regulations, or legal processes.

                        </li>
                    </ul>

                    {/* Changes to this Refund Policy */}
                    <p>
                        <strong>4. Data Security:
                        </strong>
                    </p>
                    <p>
                        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

                    </p>

                    {/* Contact Us */}
                    <p>
                        <strong>5. Your Choices:</strong>
                    </p>
                    <ul>
                        <li>
                            You can choose not to provide certain personal information, but this may limit your ability to use certain features of our website and services.

                        </li>
                        <li>
                            You can opt out of receiving promotional emails by following the instructions provided in the email or by contacting us directly.

                        </li>
                    </ul>
                    <p>
                        <strong>6. Changes to this Privacy Policy:</strong>
                    </p>
                    <ul>
                        <li>
                            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes by posting the updated Privacy Policy on our website.
                        </li>
                    </ul>
                    <p>
                        <strong>7. Contact Us:</strong>
                    </p>
                    <ul>
                        <li>
                            If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <NavLink to={`tel:${businessInfo?.phone_number}`} className="font-[800] inline-flex items-center gap-2">

                                Phone: {businessInfo?.phone_number}
                            </NavLink>
                            <NavLink to={`tel:${businessInfo?.email}`} className="inline-flex items-center gap-2 ml-2 font-[800]">

                                Email: {businessInfo?.email}
                            </NavLink>.

                        </li>
                    </ul>

                    <p>
                        By using parkingmaniac.com, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.
                    </p>

                </div>
            </div>
        </div>
    )
}

export default Privacy
