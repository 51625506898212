import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ParkImg from '../assets/images/park.jpg'
import WhoImg from '../assets/images/about-colage.png'
import { NavLink } from 'react-router-dom'

const About = () => {



  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1); // Remove the # from the hash
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);


  return (
    <>
      <section className="flex items-center justify-center md:py-[60px] py-[20px] about-banner px-[15px]" >

        <h1 className=' md:text-[50px] text-[black] uppercase text-[30px] font-[900] text-center'>About <span className='text-[#2eabe3] block'>Parking Maniac</span></h1>
      </section>

      <section className='md:py-[80px] py-[40px]' id='whoarewe'>
        <div className="container mx-auto">
          <div className="flex md:flex-row flex-col md:gap-[50px] gap-[20px]">
            <div className="block md:w-[70%] w-[100%]">
              <h3 className='text-[#2eabe3] font-[800] md:text-[50px] text-[22px]'>Who are we?</h3>
              <p className='mb-[10px] md:text-[20px] text-[14px]'>
                At Parking Maniac, we are committed to making airport parking easier and more convenient for travelers. Our platform connects you with the best parking options near airports, allowing you to reserve a spot in advance with just a few clicks. Whether you're a frequent flyer or planning a one-time trip, we ensure a hassle-free parking experience tailored to your needs.
              </p>
              <p className='mb-[30px] md:text-[20px] text-[14px]'>
                With our vast network of parking providers, competitive rates, and easy-to-use interface, we are transforming the way you park, giving you peace of mind and more time to focus on your journey. Your satisfaction and convenience are our top priorities!
              </p>
              <NavLink to='/contact' className='about-btn '>Contact for more information</NavLink>
            </div>
            <div className="block md:w-[30%] w-[100%]">
              <img src={WhoImg} className='w-full object-cover rounded-md' style={{ aspectRatio: '1/1' }} alt='' />
            </div>
          </div>

        </div>
      </section>

      <section className='md:py-[80px] py-[40px] bg-[#f6f6f6]' id='howitworks'>
        <div className="container mx-auto">
          <h3 className='text-[#2eabe3] font-[800] md:text-[50px] text-center text-[22px]'>How it works?</h3>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-3">
            <div className="block w-full bg-[white] shadow-md p-4 rounded-md">
              <h3 className='text-[#2eabe3] font-[800] md:text-[25px] text-center text-[16px]'>Choose Your Parking Lot</h3>
              <p className='md:text-[14px] text-[12px] mt-2'>
                Select the parking lot closest to the airport. Pick your check-in and check-out dates for parking.
              </p>
            </div>
            <div className="block w-full bg-[white] shadow-md p-4 rounded-md">
              <h3 className='text-[#2eabe3] font-[800] md:text-[25px] text-center text-[16px]'>Make a Deposit</h3>
              <p className='md:text-[14px] text-[12px] mt-2'>Once you’ve chosen your dates, make a deposit to secure your spot.</p>
            </div>
            <div className="block w-full bg-[white] shadow-md p-4 rounded-md">
              <h3 className='text-[#2eabe3] font-[800] md:text-[25px] text-center text-[16px]'>Confirm Your Reservation</h3>
              <p className='md:text-[14px] text-[12px] mt-2'>After the payment is done, your parking reservation will be confirmed. You're all set for your trip!</p>
            </div>
          </div>
        </div>
      </section>

      <section className='bg-white md:py-[80px] py-[40px]' id='airports'>
        <div className="container mx-auto">
          <h3 className='text-[#2eabe3] font-[800] md:text-[50px] text-center text-[22px]'>US Airports</h3>

          <ul className='md:mt-[40px] mt-[20px] grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-4 gap-3 airport-list'>
            <li>Hartsfield-Jackson Atlanta International Airport (ATL)</li>
            <li>Los Angeles International Airport (LAX)</li>
            <li>Chicago O'Hare International Airport (ORD)</li>
            <li>Dallas/Fort Worth International Airport (DFW)</li>
            <li>Denver International Airport (DEN)</li>
            <li>John F. Kennedy International Airport (JFK)</li>
            <li>San Francisco International Airport (SFO)</li>
            <li>Seattle-Tacoma International Airport (SEA)</li>
            <li>Orlando International Airport (MCO)</li>
            <li>Miami International Airport (MIA)</li>
            <li>Las Vegas McCarran International Airport (LAS)</li>
            <li>Charlotte Douglas International Airport (CLT)</li>
            <li>Newark Liberty International Airport (EWR)</li>
            <li>Boston Logan International Airport (BOS)</li>
            <li>Phoenix Sky Harbor International Airport (PHX)</li>
            <li>Houston George Bush Intercontinental Airport (IAH)</li>
            <li>Washington Dulles International Airport (IAD)</li>
            <li>Minneapolis-St. Paul International Airport (MSP)</li>
            <li>Detroit Metropolitan Airport (DTW)</li>
            <li>Philadelphia International Airport (PHL)</li>
            <li>Baltimore/Washington International Thurgood Marshall Airport (BWI)</li>
            <li>Salt Lake City International Airport (SLC)</li>
            <li>Tampa International Airport (TPA)</li>
            <li>Portland International Airport (PDX)</li>
            <li>Honolulu Daniel K. Inouye International Airport (HNL)</li>
            <li>St. Louis Lambert International Airport (STL)</li>
            <li>Kansas City International Airport (MCI)</li>
            <li>San Diego International Airport (SAN)</li>
            <li>San Jose International Airport (SJC)</li>
            <li>Oakland International Airport (OAK)</li>
            <li>Indianapolis International Airport (IND)</li>
            <li>Nashville International Airport (BNA)</li>
            <li>Raleigh-Durham International Airport (RDU)</li>
            <li>Austin-Bergstrom International Airport (AUS)</li>
            <li>Louis Armstrong New Orleans International Airport (MSY)</li>
            <li>Cleveland Hopkins International Airport (CLE)</li>
            <li>Pittsburgh International Airport (PIT)</li>
            <li>San Antonio International Airport (SAT)</li>
            <li>Ft. Lauderdale-Hollywood International Airport (FLL)</li>
            <li>Chicago Midway International Airport (MDW)</li>
            <li>Memphis International Airport (MEM)</li>
            <li>Albuquerque International Sunport (ABQ)</li>
            <li>Columbus John Glenn International Airport (CMH)</li>
            <li>Milwaukee General Mitchell International Airport (MKE)</li>
            <li>Jacksonville International Airport (JAX)</li>
            <li>Richmond International Airport (RIC)</li>
            <li>Buffalo Niagara International Airport (BUF)</li>
            <li>Providence T.F. Green International Airport (PVD)</li>
            <li>Hartford Bradley International Airport (BDL)</li>
            <li>Boise Airport (BOI)</li>
            <li>Anchorage Ted Stevens International Airport (ANC)</li>
            <li>Westchester County Airport (HPN)</li>
            <li>Long Beach Airport (LGB)</li>
            <li>Sacramento International Airport (SMF)</li>
            <li>Santa Ana John Wayne Airport (SNA)</li>
            <li>Palm Springs International Airport (PSP)</li>
            <li>Burbank Bob Hope Airport (BUR)</li>
            <li>Spokane International Airport (GEG)</li>
            <li>El Paso International Airport (ELP)</li>
            <li>Charleston International Airport (CHS)</li>
            <li>Reno-Tahoe International Airport (RNO)</li>
            <li>Colorado Springs Airport (COS)</li>
            <li>Fort Myers Southwest Florida International Airport (RSW)</li>
            <li>Louisville Muhammad Ali International Airport (SDF)</li>
            <li>Birmingham-Shuttlesworth International Airport (BHM)</li>
            <li>Tucson International Airport (TUS)</li>
            <li>Sarasota-Bradenton International Airport (SRQ)</li>
            <li>Albany International Airport (ALB)</li>
            <li>Des Moines International Airport (DSM)</li>
            <li>Little Rock Clinton National Airport (LIT)</li>
            <li>Fresno Yosemite International Airport (FAT)</li>
            <li>Wichita Dwight D. Eisenhower National Airport (ICT)</li>

          </ul>

        </div>
      </section>
    </>

  )
}

export default About
