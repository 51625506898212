import React from 'react'

const CollaborateBox = ({collabTitle, collabParah}) => {
  return (
    <div className="collab-box">
        <h3>{collabTitle}</h3>
        <p>{collabParah}</p>
    </div>
  )
}

export default CollaborateBox
