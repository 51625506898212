import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fetchReservation } from '../service/UserApi';
import { Reservation } from '../types/types';
import { useNavigate } from 'react-router';
const SearchReservations = () => {
  const schema = yup
    .object({
      reservationId: yup.string().required(),
      lastName: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate()
  const onSubmit = async (data: any) => {
    let resId: string = data?.reservationId
    if (resId) resId = resId.toLowerCase();
    if (resId.startsWith('pm-')) resId = resId.replace('pm-', '')
    if (resId.startsWith('pm')) resId = resId.replace('pm', '')
    const response: Reservation = await fetchReservation(resId)
    if (response.user_name.includes(data.lastName)) {
      navigate('/reservation/' + response.id)
      reset()
    } else {
      alert('Invalid reservation details')
      reset()
    }

  };

  return (
    <div className="bg-[#FFFFFF] border border-[#D9D9D9] p-6 fader">
      <h4 className='text-[#2B2B2B] text-[24px] clash'>Search Reservation</h4>
      <p className='text-[#707070] text-[12px]'>*Mandatory field</p>


      <div className="flex flex-col gap-4 mt-6 w-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 mt-6"
        >
          <div className="flex flex-col items-center gap-4 w-full">

            <input
              {...register("reservationId")}
              className="border border-[#E8E8E8] p-4 rounded-[10px] w-full bg-[#FAFAFA]"
              placeholder="Reservation ID"
            />
            <input
              {...register("lastName")}
              className="border border-[#E8E8E8] p-4 rounded-[10px] w-full bg-[#FAFAFA]"
              placeholder="Last name of Reserved by"
            />

          </div>

          <button type='submit' className='py-4 px-1 w-full text-center bg-[#2EABE3] text-[white] rounded-[10px] mt-2'>Search</button>
        </form>
      </div>
    </div>
  )
}

export default SearchReservations
