import React from "react";
import PaymentDetailCard from "../Payment-component/PaymentDetailCard";
import CardPaymentForm from "../Payment-component/CardPaymentForm";
import PoweredByStrip from "../assets/images/powered-by-stripe.png";

const PaymentModal = ({ onClosePaymentModal }) => {
  return (
    <div className="relative rounded-[10px] bg-[white] py-[20px] pt-0 px-[20px] max-w-[990px] w-full mx-auto my-[60px]">
      <span className="text-[#2EABE3] text-[12px] font-[400] py-[6px] mb-5 px-[10px] bg-[#E6F2F8] rounded-br-[10px] rounded-bl-[10px] block w-fit">
        {" "}
        Booking only take 60 seconds
      </span>

      <h3 className="flex items-center clash text-[#2B2B2B] text-[24px] font-[500] gap-3 flex-wrap">
        Proceed to Payment{" "}
      </h3>
      <div className="md:grid flex flex-col-reverse grid-cols-2 gap-3 ">
        <div className="block md:pb-0 pb-[80px] sticky top-0">
          <CardPaymentForm />
        </div>
        <div className="block">
          <PaymentDetailCard />
        </div>
      </div>
      <img
        className="md:max-w-[150px] max-w-[100px] w-full  absolute right-[5px] top-[5px]"
        src={PoweredByStrip}
        alt=""
      />
    </div>
  );
};

export default PaymentModal;
