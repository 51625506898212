import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GlobalContext, showToast } from "../globalContext";
import Spinner from '../components/Spinner';
import { addToNewsLetter } from '../service/UserApi';
const SignupEmailBox = () => {
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);
  const [isSubmit, setIsSubmit] = useState(false)
  const schema = yup
    .object({

      email: yup.string().email().required(),
    })
  const {
    register,
    handleSubmit,
    setError,

    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsSubmit(true)

    await addToNewsLetter(data.email)
    showToast(globalDispatch, 'Thank You');
    reset()
    setIsSubmit(false)
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="signup-email-box">
        <div className="w-full">
          <input
            type='email'
            placeholder='Email'
            {...register("email")}
          />
          <p className="text-red-500 text-xs italic ">
            {errors.email?.message}
          </p>
        </div>

        <button type='submit'>{isSubmit ? <Spinner /> : 'Subscribe'}  </button>
      </form>


    </>
  );
};

export default SignupEmailBox;
